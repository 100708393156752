<template>
    <div>
        <navbar :items="navItems"/>
        
        <data-table :dataSource="filtredUsers" :headers="headers" table-class="table-striped table-hover">
            <template slot="actions">
              <router-link :to="{name: 'add_user'}"
                class="btn btn-outline-primary btn-icon"
                @click.prevent="showAddModal"
              >
                <i class="icofont icofont-plus" />
              </router-link>
            </template>
        </data-table>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import DataTable from '../../components/dataTable/local.vue'
import Navbar from '../../components/navBar.vue'
import {COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE} from '../../components/dataTable/dataType'
const typeCompteComponent = () => import('../../components/user/typeCompte.vue')
const paysComponent = () => import('../../components/user/pays.vue')
export default {
    components: { DataTable, Navbar },
    data() {
      return {
        navItems: [
            {name: 'Utilisateurs'}
        ]
      }  
    },
    computed:{
        ...mapGetters({
            users: 'auth/users',
            me: 'auth/me'
        }),
        filtredUsers(){
            return [...this.users].filter(item => item.uid !== this.me.uid).sort((a,b) => a.fullname.localeCompare(b.fullname))
        },
        headers(){
            return [
                {label: 'Nom Complet', name: 'fullname', type: TEXT_TYPE, sortable: true},
                {label: 'Username', name: 'login', type: TEXT_TYPE, sortable: true},
                {label: 'Téléphone', name: 'telephone', type: TEXT_TYPE, sortable: true},
                {label: 'Type Compte', name: 'typeCompte', type: COMPONENT_TYPE, component: typeCompteComponent},
                {label: 'Pays', name: 'pays', type: COMPONENT_TYPE, component: paysComponent},
                {label: 'Actif', name: 'enable'},
                {label: 'Derniere connexion', name: 'lastConnexion', type: DATE_TYPE, sortable: true},
                
                // {label: 'Version', name: 'libelle', type: TEXT_TYPE, sortable: true},
                // {label: 'Deprecated', name: 'deprecat', type: TEXT_TYPE, sortable: true},
                // {label: 'Date Invalid', name: 'dateInvalid', type: DATE_TYPE, sortable: true},
                // {label: 'Action', type: COMPONENT_TYPE, component: Action}
            ]
        }
    }
}
</script>
<style lang="scss" scoped>

</style>